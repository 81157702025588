import { CodeSurfer } from "code-surfer";
import { CodeSurferColumns, Step } from "code-surfer";
import "prismjs/components/prism-dart";
import FlutterDaLogo from "../../../../../../assets/img/flutter_da_logo.png";
import materialWidgetVideo from "../../../../../../assets/mov/material_widgets_recording.mp4";
import DribbbleVideo from "../../../../../../assets/mov/dribbble.mp4";
import NutritionDesignVideo from "../../../../../../assets/mov/nutrition_design.mp4";
import NutritionPieChartResultAnimationVideo from "../../../../../../assets/mov/pie_chart_animation.mp4";
import MaterialDemoImage from "../../../../../../assets/img/material_demo.png";
import NutritionScreenImage from "../../../../../../assets/img/nutrition_screen.png";
import NutritionRootLayoutImage from "../../../../../../assets/img/nutrition_root_layout.png";
import NutritionBottomBarImage from "../../../../../../assets/img/nutrition_bottom_bar.png";
import NutritionBodyLayoutImage from "../../../../../../assets/img/nutrition_body_layout.png";
import NutritionAppBarLayoutImage from "../../../../../../assets/img/nutrition_app_bar_layout.png";
import NutritionBottomBarLayoutImage from "../../../../../../assets/img/nutrition_bottom_bar_layout.png";
import NutritionBarChartLayoutImage from "../../../../../../assets/img/nutrition_bar_chart_layout.png";
import NutritionPieChartLayoutImage from "../../../../../../assets/img/nutrition_pie_chart_layout.png";
import NutritionBottomBarResult1Image from "../../../../../../assets/img/nutrition/bottom_bar_1.png";
import NutritionBottomBarResult2Image from "../../../../../../assets/img/nutrition/bottom_bar_2.png";
import NutritionBottomBarResult3Image from "../../../../../../assets/img/nutrition/bottom_bar_3.png";
import NutritionBottomBarResult4Image from "../../../../../../assets/img/nutrition/bottom_bar_4.png";
import NutritionBottomBarResult5Image from "../../../../../../assets/img/nutrition/bottom_bar_5.png";
import NutritionAppBarResult1Image from "../../../../../../assets/img/nutrition/app_bar_1.png";
import NutritionAppBarResult2Image from "../../../../../../assets/img/nutrition/app_bar_2.png";
import NutritionAppBarResult3Image from "../../../../../../assets/img/nutrition/app_bar_3.png";
import NutritionAppBarResult4Image from "../../../../../../assets/img/nutrition/app_bar_4.png";
import NutritionAppBarResult5Image from "../../../../../../assets/img/nutrition/app_bar_5.png";
import NutritionAppBarResult6Image from "../../../../../../assets/img/nutrition/app_bar_6.png";
import NutritionBodyResult1Image from "../../../../../../assets/img/nutrition/body_1.png";
import NutritionBodyResult2Image from "../../../../../../assets/img/nutrition/body_2.png";
import NutritionBodyResult3Image from "../../../../../../assets/img/nutrition/body_3.png";
import NutritionBodyResult4Image from "../../../../../../assets/img/nutrition/body_4.png";
import NutritionBodyResult5Image from "../../../../../../assets/img/nutrition/body_5.png";
import NutritionBodyResult6Image from "../../../../../../assets/img/nutrition/body_6.png";
import NutritionBodyResult7Image from "../../../../../../assets/img/nutrition/body_7.png";
import NutritionBodyResult8Image from "../../../../../../assets/img/nutrition/body_8.png";
import NutritionBodyResult9Image from "../../../../../../assets/img/nutrition/body_9.png";
import NutritionBodyResult10Image from "../../../../../../assets/img/nutrition/body_10.png";
import NutritionDataBarResult1Image from "../../../../../../assets/img/nutrition/data_bar_1.png";
import NutritionDataBarResult2Image from "../../../../../../assets/img/nutrition/data_bar_2.png";
import NutritionDataBarResult3Image from "../../../../../../assets/img/nutrition/data_bar_3.png";
import NutritionDataBarResult4Image from "../../../../../../assets/img/nutrition/data_bar_4.png";
import NutritionDataBarResult5Image from "../../../../../../assets/img/nutrition/data_bar_5.png";
import NutritionDataBarResult6Image from "../../../../../../assets/img/nutrition/data_bar_6.png";
import NutritionDataBarResult7Image from "../../../../../../assets/img/nutrition/data_bar_7.png";
import NutritionDataBarResult8Image from "../../../../../../assets/img/nutrition/data_bar_8.png";
import NutritionBarChartResult2Image from "../../../../../../assets/img/nutrition/bar_chart_2.png";
import NutritionBarChartResult3Image from "../../../../../../assets/img/nutrition/bar_chart_3.png";
import NutritionBarChartResult4Image from "../../../../../../assets/img/nutrition/bar_chart_4.png";
import NutritionBarChartResult5Image from "../../../../../../assets/img/nutrition/bar_chart_5.png";
import NutritionPiePainterResult1Image from "../../../../../../assets/img/nutrition/pie_painter_1.png";
import NutritionPiePainterResult2Image from "../../../../../../assets/img/nutrition/pie_painter_2.png";
import NutritionPiePainterResult3Image from "../../../../../../assets/img/nutrition/pie_painter_3.png";
import NutritionPiePainterResult4Image from "../../../../../../assets/img/nutrition/pie_painter_4.png";
import NutritionPiePainterResult5Image from "../../../../../../assets/img/nutrition/pie_painter_5.png";
import NutritionPiePainterResult6Image from "../../../../../../assets/img/nutrition/pie_painter_6.png";
import NutritionPiePainterResult7Image from "../../../../../../assets/img/nutrition/pie_painter_7.png";
import NutritionPiePainterResult8Image from "../../../../../../assets/img/nutrition/pie_painter_8.png";
import NutritionPiePainterResult9Image from "../../../../../../assets/img/nutrition/pie_painter_9.png";
import NutritionPiePainterResult10Image from "../../../../../../assets/img/nutrition/pie_painter_10.png";
import NutritionPiePainterResult11Image from "../../../../../../assets/img/nutrition/pie_painter_11.png";
import NutritionPieChartResult1Image from "../../../../../../assets/img/nutrition/pie_chart_1.png";
import NutritionPieChartResult2Image from "../../../../../../assets/img/nutrition/pie_chart_2.png";
import { Split, SplitRight } from 'mdx-deck';
import { Appear } from 'mdx-deck';
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  FlutterDaLogo: FlutterDaLogo,
  materialWidgetVideo: materialWidgetVideo,
  DribbbleVideo: DribbbleVideo,
  NutritionDesignVideo: NutritionDesignVideo,
  NutritionPieChartResultAnimationVideo: NutritionPieChartResultAnimationVideo,
  MaterialDemoImage: MaterialDemoImage,
  NutritionScreenImage: NutritionScreenImage,
  NutritionRootLayoutImage: NutritionRootLayoutImage,
  NutritionBottomBarImage: NutritionBottomBarImage,
  NutritionBodyLayoutImage: NutritionBodyLayoutImage,
  NutritionAppBarLayoutImage: NutritionAppBarLayoutImage,
  NutritionBottomBarLayoutImage: NutritionBottomBarLayoutImage,
  NutritionBarChartLayoutImage: NutritionBarChartLayoutImage,
  NutritionPieChartLayoutImage: NutritionPieChartLayoutImage,
  NutritionBottomBarResult1Image: NutritionBottomBarResult1Image,
  NutritionBottomBarResult2Image: NutritionBottomBarResult2Image,
  NutritionBottomBarResult3Image: NutritionBottomBarResult3Image,
  NutritionBottomBarResult4Image: NutritionBottomBarResult4Image,
  NutritionBottomBarResult5Image: NutritionBottomBarResult5Image,
  NutritionAppBarResult1Image: NutritionAppBarResult1Image,
  NutritionAppBarResult2Image: NutritionAppBarResult2Image,
  NutritionAppBarResult3Image: NutritionAppBarResult3Image,
  NutritionAppBarResult4Image: NutritionAppBarResult4Image,
  NutritionAppBarResult5Image: NutritionAppBarResult5Image,
  NutritionAppBarResult6Image: NutritionAppBarResult6Image,
  NutritionBodyResult1Image: NutritionBodyResult1Image,
  NutritionBodyResult2Image: NutritionBodyResult2Image,
  NutritionBodyResult3Image: NutritionBodyResult3Image,
  NutritionBodyResult4Image: NutritionBodyResult4Image,
  NutritionBodyResult5Image: NutritionBodyResult5Image,
  NutritionBodyResult6Image: NutritionBodyResult6Image,
  NutritionBodyResult7Image: NutritionBodyResult7Image,
  NutritionBodyResult8Image: NutritionBodyResult8Image,
  NutritionBodyResult9Image: NutritionBodyResult9Image,
  NutritionBodyResult10Image: NutritionBodyResult10Image,
  NutritionDataBarResult1Image: NutritionDataBarResult1Image,
  NutritionDataBarResult2Image: NutritionDataBarResult2Image,
  NutritionDataBarResult3Image: NutritionDataBarResult3Image,
  NutritionDataBarResult4Image: NutritionDataBarResult4Image,
  NutritionDataBarResult5Image: NutritionDataBarResult5Image,
  NutritionDataBarResult6Image: NutritionDataBarResult6Image,
  NutritionDataBarResult7Image: NutritionDataBarResult7Image,
  NutritionDataBarResult8Image: NutritionDataBarResult8Image,
  NutritionBarChartResult2Image: NutritionBarChartResult2Image,
  NutritionBarChartResult3Image: NutritionBarChartResult3Image,
  NutritionBarChartResult4Image: NutritionBarChartResult4Image,
  NutritionBarChartResult5Image: NutritionBarChartResult5Image,
  NutritionPiePainterResult1Image: NutritionPiePainterResult1Image,
  NutritionPiePainterResult2Image: NutritionPiePainterResult2Image,
  NutritionPiePainterResult3Image: NutritionPiePainterResult3Image,
  NutritionPiePainterResult4Image: NutritionPiePainterResult4Image,
  NutritionPiePainterResult5Image: NutritionPiePainterResult5Image,
  NutritionPiePainterResult6Image: NutritionPiePainterResult6Image,
  NutritionPiePainterResult7Image: NutritionPiePainterResult7Image,
  NutritionPiePainterResult8Image: NutritionPiePainterResult8Image,
  NutritionPiePainterResult9Image: NutritionPiePainterResult9Image,
  NutritionPiePainterResult10Image: NutritionPiePainterResult10Image,
  NutritionPiePainterResult11Image: NutritionPiePainterResult11Image,
  NutritionPieChartResult1Image: NutritionPieChartResult1Image,
  NutritionPieChartResult2Image: NutritionPieChartResult2Image,
  Split: Split,
  SplitRight: SplitRight,
  Appear: Appear,
  React: React
};